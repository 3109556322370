import * as Api from '@ViewModels';
import * as Tabs from '@radix-ui/react-tabs';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import { stringify as toQueryString } from 'query-string';
import * as React from 'react';
import { RouteComponentProps, useHistory, useLocation, useRouteMatch, withRouter } from 'react-router-dom';
import { IContactSelection, ICreateCampaignRequest, ILocationState } from '../../../models';
import { useEventLogging } from '../../../models/Logging';
import { searchRequestToQueryStringParams } from '../../../models/UiUtils';
import { useFullscreenModal, useUserSession } from '../../../models/hooks/appStateHooks';
import { MultiContainerHeader } from '../../components/MultiContainerHeader';
import { baseStyleSheet } from '../../styles/styles';
import { CompaniesContainer } from './CompaniesContainer';
import { PeopleContainer } from './PeopleContainer';
import { DefaultPeopleFilter, DefaultPeopleSort } from './PeopleContainer/PeopleContext';
import { styleSheet } from './styles';

interface IProps extends RouteComponentProps<any> {
	className?: string;
	styles?: StyleDeclarationValue[];
}

const TabValues = { Companies: 'Companies', People: 'People' } as const;

const PeopleV2Base: React.FC<IProps> = observer(props => {
	const { className, styles } = props;
	const { path } = useRouteMatch();
	const { logEvent } = useEventLogging();
	const location = useLocation();
	const userSession = useUserSession();
	const history = useHistory();
	const fullscreenModal = useFullscreenModal();

	const [tagAlertCommandObj, setTagAlertCommandObj] = React.useState<Api.IViewTagAlertContactsCommand>(null);
	const selectedTab = React.useMemo(() => {
		if (path.includes('companies')) {
			return TabValues.Companies;
		}
		return TabValues.People;
	}, [path]);

	const onContactLoad = (filter: Api.IBulkContactsRequest, contacts: Api.ContactViewModel[]) => {
		if (
			tagAlertCommandObj &&
			filter.filter.criteria.find(x => x.property === Api.ContactFilterCriteriaProperty.TagAlert)
		) {
			if (tagAlertCommandObj.op === 'send') {
				if (contacts?.length > 0) {
					const locationState: ILocationState<any, ICreateCampaignRequest<IContactSelection>> = {
						model: {
							context: {
								bulkFilterRequestOptions: {
									readOnlySearchCriteria: true,
								},
								contacts,
							},
							type: 'Contacts',
						},
					};
					fullscreenModal?.history?.push({
						pathname: '/email/campaigns/create/from-selection',
						state: locationState,
					});
				}
			}

			// clear the tag alert command
			setTagAlertCommandObj(null);
		}
	};

	React.useEffect(() => {
		const queryCommand = Api.QueryCommands.People.parse(location.search);
		if (queryCommand && queryCommand.command === 'tagalert') {
			const tagAlertCommand = queryCommand as Api.IViewTagAlertContactsCommand;

			if (tagAlertCommand.id) {
				const tagAlert = new Api.TagAlertViewModel(userSession, {
					id: tagAlertCommand.id,
				});
				const promise = tagAlert.load();
				if (promise) {
					logEvent('TagAlertLoad', { id: tagAlertCommand.id });
					promise
						.then(() => {
							// create the initial filter request using the tag
							const filterRequest: Api.IContactsFilterRequest = {
								...DefaultPeopleFilter,
								criteria: [
									{
										property: Api.ContactFilterCriteriaProperty.TagAlert,
									},
									{
										property: Api.ContactFilterCriteriaProperty.Tag,
										value: tagAlert.tagValue,
									},
								],
							};
							const sortDescriptor: Api.ISortDescriptor = {
								...DefaultPeopleSort,
							};

							setTagAlertCommandObj(tagAlertCommand.op === 'send' ? tagAlertCommand : null);
							history.push(
								`/people/?${toQueryString(searchRequestToQueryStringParams(filterRequest, sortDescriptor))}`
							);
						})
						.catch((error: Api.IOperationResultNoValue) => {
							logEvent('TagAlertLoad-Error', { ...error });
							history.push(location.pathname);
						});
				} else {
					history.push(location.pathname);
				}
			} else {
				const filterRequest: Api.IContactsFilterRequest = {
					...DefaultPeopleFilter,
					criteria: [
						{
							property: Api.ContactFilterCriteriaProperty.TagAlert,
						},
					],
				};

				const sortDescriptor: Api.ISortDescriptor = { ...DefaultPeopleSort };
				setTagAlertCommandObj(null);
				history.push(`/people/?${toQueryString(searchRequestToQueryStringParams(filterRequest, sortDescriptor))}`);
			}
		} else if (queryCommand && queryCommand.command === 'tag') {
			const tagContactCommand = queryCommand as Api.IViewTagContactsCommand;
			if (tagContactCommand.id) {
				const filterRequest: Api.IContactsFilterRequest = {
					...DefaultPeopleFilter,
					criteria: [
						{
							property: Api.ContactFilterCriteriaProperty.Tag,
							value: tagContactCommand.id,
						},
					],
				};

				const sortDescriptor: Api.ISortDescriptor = { ...DefaultPeopleSort };
				setTagAlertCommandObj(null);
				history.push(`/people/?${toQueryString(searchRequestToQueryStringParams(filterRequest, sortDescriptor))}`);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSelectedTabChanged = (tabValue: (typeof TabValues)[keyof typeof TabValues]) => {
		switch (tabValue) {
			case TabValues.Companies:
				history.push('/companies');
				break;
			case TabValues.People:
				history.push('/people');
				break;
			default:
				throw new Error('Invalid tab selected');
		}
	};

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
			<MultiContainerHeader
				appBarContentStyles={[styleSheet.appBarContent]}
				appBarHeader={<h1 className={css(baseStyleSheet.breadcrumbTitle)}>Contacts</h1>}
				appBarStyles={[styleSheet.appBar]}
			/>
			<Tabs.Root
				className={`tab-view ${css(styleSheet.tabViewContainer)}`}
				value={selectedTab}
				// @ts-ignore
				onValueChange={onSelectedTabChanged}
			>
				<Tabs.List className={`tab-view-tabbar-tabs ${css(styleSheet.tabViewTabs)}`}>
					<Tabs.Trigger
						value={TabValues.People}
						className={`tab-view-tabbar-item ${selectedTab === TabValues.People ? `tabbar-item-selected` : ''} ${css(
							styleSheet.tabViewItem,
							selectedTab === TabValues.People && styleSheet.tabViewSelectedItem
						)}`}
					>
						<span>People</span>
					</Tabs.Trigger>
					{userSession.account.preferences?.hideCompanies ||
					userSession.userRole === Api.EUserRole.LimitedUser ? null : (
						<Tabs.Trigger
							value={TabValues.Companies}
							className={`tab-view-tabbar-item ${
								selectedTab === TabValues.Companies ? `tabbar-item-selected` : ''
							} ${css(styleSheet.tabViewItem, selectedTab === TabValues.Companies && styleSheet.tabViewSelectedItem)}`}
						>
							<span>Companies</span>
						</Tabs.Trigger>
					)}
				</Tabs.List>
				<Tabs.Content
					value={TabValues.People}
					className={`tab-view-content ${css(selectedTab === TabValues.People && styleSheet.tabViewContent)}`}
				>
					<PeopleContainer onContactsLoad={onContactLoad} />
				</Tabs.Content>
				<Tabs.Content
					value={TabValues.Companies}
					className={`tab-view-content ${css(selectedTab === TabValues.Companies && styleSheet.tabViewContent)}`}
				>
					<CompaniesContainer />
				</Tabs.Content>
			</Tabs.Root>
		</div>
	);
});

export const PeopleV2 = withRouter(PeopleV2Base);
