export enum AmountFilterOption {
	Lifetime = 'Lifetime',
	OneTime = 'One-Time',
}

export enum DonationPropertyOption {
	DonorName = 'Donor Name',
	Campaign = 'Campaign',
}

export enum DonationTypeOption {
	HasDonated = 'Has Donated',
	NotDonated = 'Not Donated',
}

export enum DateRangeOption {
	SevenDays = '7 days',
	OneMonth = '1 month',
	ThreeMonths = '3 months',
	SixMonths = '6 months',
	OneYear = '1 year',
	ThreeYears = '3 years',
	Custom = 'Custom Range',
}
