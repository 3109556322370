import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { CampaignTabListRightPortalID } from '..';
import { useFullscreenModal } from '../../../../models/hooks/appStateHooks';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { Portal } from '../../../components/Portal';
import { TextRegistrationRequiredModal } from '../../../components/texting/TextRegistrationRequiredModal';
import { bs } from '../../../styles/styles';
import { useTextingCampaignRegistrationContext } from '../../Texting/TextingCampaignRegistrationPrompt/context';
import { useTextingRegistrationStatus } from '../../TextingRegistration/TextingRegistrationForm/hooks/useTextingRegistrationStatus';
import { TextingCampaignBrowserCards } from './TextingCampaignBrowserCards';
import { styleSheet } from './styles';

export function TextingTabPage() {
	const {
		showRegistrationRequiredModal,
		setShowRegistrationRequiredModal,
		isLoading,
		textSettings,
		checkShowRegistration,
	} = useTextingCampaignRegistrationContext();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	React.useEffect(checkShowRegistration, []);

	const fullscreenModal = useFullscreenModal();
	const history = useHistory();

	const onCloseRegistrationWarningClicked = () => {
		setShowRegistrationRequiredModal(false);
		history.push('/campaigns/emails');
	};

	return isLoading ? (
		<div className={css(styleSheet.grayBackground, bs.wFull, bs.boxBorder, bs.hFull, styleSheet.paddingTop)}>
			<LoadingSpinner type='small' />
		</div>
	) : (
		<div className={css(styleSheet.grayBackground, bs.wFull, bs.boxBorder, bs.hFull)}>
			<div className={css(bs.flex, bs.justifyEnd, bs.px6, bs.pt5, bs.pb2, bs.boxBorder)}>
				<button className={css(bs.ctaButton)} onClick={() => fullscreenModal.history.push('/texting-campaign/search')}>
					Create New Text Campaign
				</button>
			</div>
			<TextingCampaignBrowserCards />
			<Portal destination={CampaignTabListRightPortalID}>
				<TextingTabRegistrationStatus />
			</Portal>
			<TextRegistrationRequiredModal
				showRegistrationRequiredModal={showRegistrationRequiredModal}
				telephonyTextSettings={textSettings}
				onRequestClose={onCloseRegistrationWarningClicked}
			/>
		</div>
	);
}

function TextingTabRegistrationStatus() {
	const textingRegistrationStatus = useTextingRegistrationStatus();
	if (textingRegistrationStatus.isLoading) {
		return null;
	}
	if (textingRegistrationStatus.brandStatus == null) {
		return (
			<div className={css(bs.flex, bs.flexCol, bs.gap1)}>
				<p className={css(bs.m0, bs.p0, bs.textSm, bs.textHeader)}>Ready to send text campaigns?</p>
				<Link className={css(bs.textXs, bs.textBrandPrimary, bs.fontBold)} to='/texting/registration'>
					Get registered today &rarr;
				</Link>
			</div>
		);
	}
	if (
		textingRegistrationStatus.brandStatus === Api.TextingBrandStatus.Pending ||
		textingRegistrationStatus.campaignStatus === Api.CampaignRegistrationStatus.Pending
	) {
		return (
			<p className={css(bs.m0, bs.p0, bs.textSm)}>
				Registration Status: <span className={css(bs.textXs, bs.textAlternateTitle, bs.fontBold)}>Processing</span>
			</p>
		);
	}
	if (
		textingRegistrationStatus.brandStatus === Api.TextingBrandStatus.Failed ||
		textingRegistrationStatus.campaignStatus === Api.CampaignRegistrationStatus.Failed ||
		textingRegistrationStatus.brandStatus === Api.TextingBrandStatus.Unverified ||
		textingRegistrationStatus.campaignStatus === Api.CampaignRegistrationStatus.Declined
	) {
		return (
			<div className={css(bs.flex, bs.flexCol, bs.gap1)}>
				<p className={css(bs.m0, bs.p0, bs.textSm)}>
					Registration Status: <span className={css(bs.textXs, bs.textWarningDark, bs.fontBold)}>Declined</span>
				</p>
				<p className={css(bs.p0, bs.m0, bs.textXs, bs.textHeader)}>Please reach out to your CSM</p>
			</div>
		);
	}
	if (textingRegistrationStatus.campaignStatus === 'Accepted') {
		return (
			<p className={css(bs.m0, bs.p0, bs.textSm)}>
				Registration Status: <span className={css(bs.textXs, bs.textSuccess, bs.fontBold)}>Accepted</span>
			</p>
		);
	}
	return null;
}
