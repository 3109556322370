import { StyleSheet } from 'aphrodite';
import { white } from '../../styles/colors';

const masterWidth = 420;

export const styleSheet = StyleSheet.create({
	splitView: {
		boxSizing: 'border-box',
		display: 'flex',
		position: 'relative',
		height: '100%',
	},
	splitViewDetail: {
		flex: '1 0 0',
		height: '100%',
		width: `calc(100% - ${masterWidth}px)`,
	},
	splitViewDivider: {
		background: '#ccc',
		bottom: 0,
		left: masterWidth,
		padding: 'inherit',
		pointerEvents: 'none',
		position: 'absolute',
		top: 0,
		width: 1,
		zIndex: 1,
	},
	splitViewMaster: {
		boxSizing: 'border-box',
		flex: `0 0 ${masterWidth}px`,
		height: '100%',
		overflowY: 'auto',
		paddingRight: 30,
		paddingTop: 20,
		background: white,
	},
});
