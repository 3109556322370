import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import Waypoint from 'react-waypoint';
import { useTagsFromBulkContactRequest } from '../../../../../hooks/useTagsFromBulkContactRequest';
import { useEventLogging } from '../../../../../models/Logging';
import { useErrorMessages, useUserSession } from '../../../../../models/hooks/appStateHooks';
import { useModal } from '../../../../../models/hooks/useModal';
import { Checkbox } from '../../../../components/Checkbox';
import { TinyPopover } from '../../../../components/TinyPopover';
import { VisibilityIcon } from '../../../../components/VisibilityIcon';
import { EntityTablePlaceholder } from '../../../../components/entities/EntityTablePlaceholder';
import { DisclosureIcon } from '../../../../components/svgs/icons/DisclosureIcon';
import { DefaultPeopleFilter, DefaultPeopleSort } from '../PeopleContext';
import { PeopleRow } from '../PeopleRow';
import { useIsDuplicateGroupingToggled } from '../hooks/useIsDuplicateGroupingToggled';
import { PeopleDuplicateContainerRow } from './PeopleDuplicateContainerRow';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	onSearchChange: (search: string) => void;
	filter: Api.IBulkContactsRequest;
	sort: Api.ISortDescriptor<string>;
	load: ({ newFilter, newSort }: { newFilter?: Api.IBulkContactsRequest; newSort?: Api.ISortDescriptor }) => void;
	contactsVM: Api.ContactsViewModel;
}

export const PeopleTable: React.FC<IProps> = observer(
	({ className, styles, onSearchChange, filter, sort, contactsVM, load }) => {
		const userSession = useUserSession();
		const errorMessages = useErrorMessages();
		const { logApiError } = useEventLogging();
		const sortPopover = useModal(false, Api.VmUtils.Noop, []);
		const isDuplicateGroupingToggled = useIsDuplicateGroupingToggled({ request: filter });
		const loadMore = () => {
			contactsVM
				.getContacts(
					{
						...filter,
						groupByDuplicate: isDuplicateGroupingToggled,
					},
					sort,
					25,
					{ expand: isDuplicateGroupingToggled ? 'DuplicateContacts' : undefined }
				)
				?.catch((err: Api.IOperationResultNoValue) => {
					errorMessages.pushApiError(err);
					logApiError('LoadMoreContacts-Error', err);
				});
		};
		const onClearButtonClicked = () => {
			load({ newFilter: DefaultPeopleFilter, newSort: DefaultPeopleSort });
			onSearchChange('');
		};
		const onSelectAllCheckedChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
			if (e.target.checked || contactsVM.selectionState === Api.ESelectionState.Some) {
				contactsVM.selectAll();
			} else {
				contactsVM.deselectAll();
			}
		};
		const onSort = (newSort: Api.ISortDescriptor) => () => {
			sortPopover.setIsOpen(false)();
			load({ newSort });
		};
		const tagsToHighlight = useTagsFromBulkContactRequest(filter);
		const sortDirectionDesc = sort.sort === 'desc';
		const getNameHeader = () => {
			const sortByStringValue = sort.sortBy;
			switch (sortByStringValue) {
				case 'firstName':
					return 'First Name';
				case 'lastName':
					return 'Last Name';
				default:
					return 'Name';
			}
		};
		const selectContactRow = (c: Api.IContact) => {
			const contactVm = new Api.ContactViewModel(userSession, c);
			if (contactsVM.isAllSelected) {
				contactsVM.excludedContacts.removeItems([contactVm]);
			} else {
				contactsVM.selectedContacts.add(contactVm);
			}
		};
		const deselectContactRow = (c: Api.IContact) => {
			const contactVm = new Api.ContactViewModel(userSession, c);
			if (contactsVM.isAllSelected) {
				contactsVM.excludedContacts.add(contactVm);
			} else {
				contactsVM.selectedContacts.removeItems([contactVm]);
			}
		};
		const onToggleContactSelection = (c: Api.IContact) => {
			const contactVm = new Api.ContactViewModel(userSession, c);
			const isSelected = contactsVM.isContactSelected(contactVm.id!);
			if (!isSelected) {
				selectContactRow(c);
			} else {
				deselectContactRow(c);
			}
		};
		const nameHeader = getNameHeader();
		return (
			<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
				<div className={css(styleSheet.tableHeader)}>
					<div className={css(styleSheet.tableHeaderElement, styleSheet.selectAll)}>
						<Checkbox
							id='select-all-contacts'
							childrenPosition='left'
							backgroundFillColor='white'
							checked={contactsVM.selectionState === 'all' || contactsVM.selectionState === 'some'}
							partial={contactsVM.selectionState === 'some'}
							onChange={onSelectAllCheckedChanged}
						>
							<span className={css(styleSheet.selectAllText)}>All</span>
						</Checkbox>
					</div>
					<div className={css(styleSheet.tableHeaderElement, styleSheet.visibility)}>
						<VisibilityIcon />
					</div>
					<div className={css(styleSheet.tableHeaderElement, styleSheet.avatar)}>{/* Avatar no header */}</div>
					<TinyPopover
						anchor={
							<div onClick={sortPopover.setIsOpen(true)}>
								{nameHeader}
								<span className={css(styleSheet.sortIcon, sortDirectionDesc && styleSheet.sortIconFlipped)}>
									<DisclosureIcon type='chevron' />
								</span>
							</div>
						}
						isOpen={sortPopover.isOpen}
						onRequestClose={sortPopover.onRequestClose}
						align='start'
						dismissOnOutsideAction={true}
						anchorStyles={[styleSheet.tableHeaderElement, styleSheet.name]}
					>
						<div className={css(styleSheet.sortMenu)}>
							<div className={css(styleSheet.sortMenuItem)} onClick={onSort({ sort: 'asc', sortBy: 'firstName' })}>
								First Name A-Z
							</div>
							<div className={css(styleSheet.sortMenuItem)} onClick={onSort({ sort: 'desc', sortBy: 'firstName' })}>
								First Name Z-A
							</div>
							<div className={css(styleSheet.sortMenuItem)} onClick={onSort({ sort: 'asc', sortBy: 'lastName' })}>
								Last Name A-Z
							</div>
							<div className={css(styleSheet.sortMenuItem)} onClick={onSort({ sort: 'desc', sortBy: 'lastName' })}>
								Last Name Z-A
							</div>
						</div>
					</TinyPopover>
					<div className={css(styleSheet.tableHeaderElement, styleSheet.tags)}>Tag</div>
					<div className={css(styleSheet.tableHeaderElement, styleSheet.info)}>Contact Info</div>
					<div className={css(styleSheet.tableHeaderElement, styleSheet.connections)}>Connections</div>
					<div style={{ width: 30 }} />
				</div>
				<div className={css(styleSheet.tableBody, styleSheet.tableBodyHappiness)}>
					{contactsVM.fetchResults.length > 0 || !!contactsVM.isFetchingResults ? (
						contactsVM.fetchResults.map(contactVm => {
							const contact = contactVm.toJs();
							const isSelected = contactsVM.isContactSelected(contact.id!);
							if (contact.duplicateContacts?.length) {
								return (
									<PeopleDuplicateContainerRow
										filter={filter}
										key={`people-duplicate-container-row-${contactVm.id}`}
										contacts={contactsVM}
										contact={contact}
										onToggleContactSelection={onToggleContactSelection}
										selectContactRow={selectContactRow}
										deselectContactRow={deselectContactRow}
									/>
								);
							}
							return (
								<PeopleRow
									isSelected={isSelected}
									onToggleSelection={() => onToggleContactSelection(contact)}
									contact={contact}
									key={`people-row-${contact.id}`}
									tagsToHighlight={tagsToHighlight}
								/>
							);
						})
					) : (
						<EntityTablePlaceholder
							className={css(styleSheet.placeholder)}
							onClearButtonClicked={onClearButtonClicked}
							type='contact'
						/>
					)}
					{contactsVM.fetchResults.length > 0 && !contactsVM.isFetchingResults && (
						<Waypoint key='load-more-trigger' bottomOffset='-400px' onEnter={loadMore} />
					)}
				</div>
			</div>
		);
	}
);
