import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { useEventLogging } from '../../../../models/Logging';
import { getFormattedPhoneNumber } from '../../../../models/UiUtils';
import { useErrorMessages, useTextMessaging, useUserSession } from '../../../../models/hooks/appStateHooks';
import { baseStyleSheet } from '../../../styles/styles';
import { ContactSection } from './components/ContactSection';
import { ConversationList } from './components/ConversationList';
import { NewConversation } from './components/NewConversation';
import { SelectedConversation } from './components/SelectedConversation';
import { ShowUnreadCheckbox } from './components/ShowUnreadCheckbox';
import { YourNumber } from './components/YourNumber';
import { TextMessageConversationsContext, useTextMessageConversations } from './context';
import { useRefreshConversations } from './hooks';
import { styleSheet } from './styles';

interface ITextingRouteProps {
	phoneNumberId?: string;
	conversationId?: string;
	textMessageId?: string;
}

interface IProps extends ITextingRouteProps {
	className?: string;
	showMessagesOnly?: boolean;
	requiresCampaignRegistration?: boolean;
	isRegistrationInProgress?: boolean;
}

// example route with params: https://localhost:3000/#/texting/9c184c2c-4faa-4cdd-aa2d-6bd609aaa5f5/4389b6d6-925c-437f-8541-376e9f2c5975

export const ConversationsBase: React.FC<IProps> = ({
	className = '',
	conversationId,
	phoneNumberId,
	showMessagesOnly,
	requiresCampaignRegistration,
	isRegistrationInProgress,
}) => {
	const userSession = useUserSession();
	const textMessagingVM = useTextMessaging();
	const errorMessages = useErrorMessages();
	const logger = useEventLogging();
	const history = useHistory();
	const router = useRouteMatch<ITextingRouteProps>();

	const onError = React.useCallback((error: any) => errorMessages.pushApiError(Api.asApiError(error)), [errorMessages]);
	useRefreshConversations();

	const [missingNumbers, setMissingNumbers] = useState<Api.ITextRecipient[]>([]);
	const [redirectTo, setRedirectTo] = useState<string>(null);
	const context = useTextMessageConversations();
	const {
		recipients,
		selectedConversation,
		selectedRecipient,
		selectedRecipientIndex,
		setRecipients,
		setSelectedConversation,
		setSelectedRecipient,
		setSelectedRecipientIndex,
		setShowNewConversationSearch,
		showNewConversationSearch,
	} = context;

	useEffect(() => {
		const getDataFromRoute = () => {
			if (!conversationId || !phoneNumberId) {
				// eslint-disable-next-line react-hooks/exhaustive-deps
				phoneNumberId = router?.params?.phoneNumberId;
				// eslint-disable-next-line react-hooks/exhaustive-deps
				conversationId = router?.params?.conversationId;
			}
			setMissingNumbers([]);

			if (phoneNumberId && conversationId) {
				if (phoneNumberId === textMessagingVM.id) {
					const conversation = textMessagingVM.conversations.find(c => c.id === conversationId);
					if (conversation) {
						setShowNewConversationSearch(false);
						setSelectedConversation(conversation);

						const recipientsList = conversation?.toNumbers.map(num => {
							if (num.contact) {
								const textRecipient = new Api.TextRecipientViewModel(userSession, num.contact);
								return textRecipient;
							} else {
								return {
									metadata: num.number,
									value: num.number.standard,
								};
							}
						});

						if (recipientsList?.length) {
							const mMissingNumbers = conversation?.toNumbers.filter(toNum => !!toNum.contact);
							Promise.all(
								recipientsList
									.filter(r => r instanceof Api.TextRecipientViewModel)
									// FOLLOWUP: Resolve
									// @ts-ignore
									.map(async (r: Api.TextRecipientViewModel) => {
										try {
											// have to load all TextRecipientViewModels so all their phone numbers are available
											await r.load();
										} catch (error) {
											logger.logApiError('TextRecipientLoad-Error', error);
											console.error('Error loading TextRecipientViewModel', error);
										}
										// confirm that the TextRecipientViewModel still has a phone number
										// that is associated with this conversation.
										//
										// if one is NOT found. indicates that the TextRecipientViewModel's phone number
										// has been changed since this conversation was created and this conversation
										// is no longer up to date.
										conversation?.toNumbers?.forEach(toNum => {
											r.phoneNumbers?.forEach(p => {
												if (p.metadata?.standard === toNum.number.standard) {
													for (let i = 0; i < mMissingNumbers.length; i++) {
														if (mMissingNumbers[i].contact.id === r.id) {
															mMissingNumbers.splice(i, 1);
															break;
														}
													}
												}
											});
										});
									})
							)
								.then(() => {
									if (mMissingNumbers.length) {
										setMissingNumbers(mMissingNumbers);
									}
								})
								.catch(error => {
									logger.logApiError('TextRecipientLoad-Error', error);
								});
							setRecipients(recipientsList);
						}
					} else {
						throw new Error('Conversation not found.');
					}
				} else {
					throw new Error('Unknown phone number found.');
				}
			} else if (phoneNumberId || conversationId) {
				history.push(`/texting`);
			}
		};
		if (!textMessagingVM.conversations?.length) {
			textMessagingVM
				.getConversations()
				.then(() => getDataFromRoute())
				.catch(onError);
		} else {
			getDataFromRoute();
		}
	}, [router?.params, textMessagingVM]);

	useEffect(() => {
		if (recipients?.length) {
			let recipientNeedsResolving = null;
			if (!selectedConversation) {
				// check to see if there are any invalid recipients and return the first
				recipientNeedsResolving = recipients.find(r => {
					return r instanceof Api.TextRecipientViewModel && r.needsResolving;
				});
			}
			if (recipientNeedsResolving) {
				setSelectedRecipient(recipientNeedsResolving as Api.TextRecipientViewModel);
				return;
			}
			let recipient = null;
			let index = selectedRecipientIndex;
			if (recipients?.length === 1) {
				recipient = recipients[0];
				index = 0;
			} else if (recipients?.length > 1 && recipients?.length >= selectedRecipientIndex + 1) {
				recipient = recipients[selectedRecipientIndex];
			}
			setSelectedRecipientIndex(index);
			setSelectedRecipient(recipient instanceof Api.TextRecipientViewModel ? recipient : null);
		} else {
			setSelectedRecipient(null);
			setSelectedRecipientIndex(null);
		}
	}, [recipients, selectedConversation, selectedRecipientIndex, setSelectedRecipient, setSelectedRecipientIndex]);

	useEffect(() => {
		selectedRecipient?.load();
	}, [selectedRecipient]);

	useEffect(() => {
		if (showNewConversationSearch && recipients?.length > 0) {
			resolveConversation(recipients);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [recipients, showNewConversationSearch]);

	const handleCreateConversationClick = () => {
		setMissingNumbers([]);
		setRecipients([]);
		setSelectedConversation(null);
		setSelectedRecipientIndex(null);
		setShowNewConversationSearch(true);
	};

	const resolveConversation = async (allRecipients: (Api.TextRecipientViewModel | Api.IPhoneNumber)[]) => {
		const phoneNumbers = allRecipients.reduce<Api.IPhoneNumber[]>((result, r) => {
			const phoneNumber =
				r instanceof Api.TextRecipientViewModel
					? r.selectedPhoneNumber
						? r.selectedPhoneNumber
						: r.phoneNumbers.length === 1
							? r.phoneNumbers[0] // Only set to this if length is 1 when no selected Phone number exists the find below will handle keeping the selected number for purposes of selecting a conversation
							: r.phoneNumbers?.find(x =>
									allRecipients?.find(y => y?.value === x?.value || y.metadata?.standard === x.metadata?.standard)
								)
					: r;
			if (phoneNumber) {
				result.push(phoneNumber);
			}
			return result;
		}, []);

		if (phoneNumbers.length === allRecipients.length) {
			const conversation = await textMessagingVM.getConversationByPhoneNumbers(phoneNumbers);
			setSelectedConversation(conversation);
		} else {
			setSelectedConversation(null);
		}
	};

	if (redirectTo) {
		return <Redirect from='/texting' to={redirectTo} push={true} />;
	}

	return (
		<TextMessageConversationsContext.Provider value={context}>
			<div className={`${css(styleSheet.container)} ${className}`}>
				<div className={css(styleSheet.column, styleSheet.conversationsCol, showMessagesOnly && baseStyleSheet.hidden)}>
					<YourNumber
						onCreateConversationClick={handleCreateConversationClick}
						phoneNumber={getFormattedPhoneNumber(textMessagingVM.phoneNumberOrder.number)}
					/>
					<ShowUnreadCheckbox
						onChange={handleCreateConversationClick}
						onError={onError}
						textMessagingVM={textMessagingVM}
					/>
					<ConversationList />
				</div>
				<div className={`${css(styleSheet.conversationContainer)} conversation-container`}>
					<NewConversation missingNumbers={missingNumbers} />
					<SelectedConversation
						requiresCampaignRegistration={requiresCampaignRegistration}
						isRegistrationInProgress={isRegistrationInProgress}
					/>
				</div>
				<div
					className={css(styleSheet.column, styleSheet.contactInfoContainer, showMessagesOnly && baseStyleSheet.hidden)}
				>
					<ContactSection setRedirectTo={setRedirectTo} />
				</div>
			</div>
		</TextMessageConversationsContext.Provider>
	);
};

export const Conversations = observer(ConversationsBase);
