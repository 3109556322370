import { inputBorderColor, borderColor } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		padding: 16,
	},
	dropdownAnchor: {
		alignItems: 'center',
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'space-between',
		fontSize: 14,
		minWidth: 180,
		padding: 10,
	},
	dropdownIcon: {
		transform: 'rotate(90deg)',
	},
	dropdownInline: {
		borderColor: inputBorderColor,
		borderRadius: 8,
		borderStyle: 'solid',
		borderWidth: 1,
		boxSizing: 'border-box',
		maxWidth: 600,
		overflowY: 'visible',
		height: '100%',
	},
	dropdownInlineOpen: {
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
	},
	dropdown: {
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
		padding: 16,
	},
	dropdownMenu: {
		background: '#fff',
		marginTop: 2,
		maxHeight: 550,
		overflowY: 'auto',
		minWidth: '300px !important',
		display: 'flex',
		flexDirection: 'column',
		left: '-1px !important',
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 8,
		borderTopRightRadius: 0,
	},
	dropdownSeparator: {
		borderBottom: `1px solid ${borderColor}`,
	},
	option: {
		padding: 8,
		':hover': {
			cursor: 'pointer',
			backgroundColor: borderColor,
		},
	},
	selected: {
		backgroundColor: borderColor,
	},
});
