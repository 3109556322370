import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { baseStyleSheet } from '../../../../styles/styles';
import { Dropdown } from '../../../Dropdown';
import { RadioButton } from '../../../RadioButton';
import { DateRangeOption, DonationTypeOption } from '../../../dataBoards/donations/models';
import { DisclosureIcon } from '../../../svgs/icons/DisclosureIcon';
import { styleSheet } from './styles';
import { styleSheet as dropdownStyles } from '../../DropdownComponent/styles';
import { CustomDateRange } from '../../../CustomDateRange';

interface IDayOption {
	key: keyof typeof DateRangeOption;
	label: string;
	value: string;
}

const dayOptions: IDayOption[] = (Object.keys(DateRangeOption) as (keyof typeof DateRangeOption)[]).map(key => {
	const value = DateRangeOption[key];
	if (value === DateRangeOption.Custom) {
		return {
			key,
			label: value,
			value,
		};
	}
	const parts = value.split(' ');
	return {
		key,
		label: value,
		value: moment()
			.subtract(parseInt(parts[0], 10), parts[1].toLowerCase() as moment.unitOfTime.DurationConstructor)
			.format('YYYY-MM-DD'),
	};
});

export const DateRangeFilterDropdown = ({
	minimumDonationDate,
	setMinimumDonationDate,
	maximumDonationDate,
	setMaximumDonationDate,
	selectedDonationType,
	setSelectedDonationType,
	showCustomRange = false,
}: {
	minimumDonationDate: string;
	setMinimumDonationDate: React.Dispatch<React.SetStateAction<string>>;
	maximumDonationDate: string;
	setMaximumDonationDate: React.Dispatch<React.SetStateAction<string>>;
	selectedDonationType: DonationTypeOption;
	setSelectedDonationType: React.Dispatch<React.SetStateAction<DonationTypeOption>>;
	showCustomRange?: boolean;
}) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const [selectedDayOption, setSelectedDayOption] = React.useState<IDayOption>(null);

	const getDateRangeMessage = () => {
		if (!minimumDonationDate || !selectedDayOption) {
			return 'Date Range';
		}
		const key = selectedDayOption.key;
		return `${selectedDonationType}/${DateRangeOption[key]}`;
	};
	const dateRangeMessage = getDateRangeMessage();

	const selectDateRangeOption = (newDayOption: IDayOption, ev: React.MouseEvent<HTMLDivElement>) => {
		if (newDayOption.value === DateRangeOption.Custom) {
			setSelectedDayOption(newDayOption);
			ev.stopPropagation();
			ev.preventDefault();
		} else {
			setMinimumDonationDate(newDayOption.value);
			setIsOpen(false);
		}
	};

	React.useEffect(() => {
		if (maximumDonationDate) {
			const customDayOption = dayOptions.find(option => option.value === DateRangeOption.Custom);
			setSelectedDayOption(customDayOption);
			return;
		}
		const match = dayOptions.find((option: IDayOption) => {
			return option.value === minimumDonationDate;
		});
		setSelectedDayOption(match);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [minimumDonationDate]);

	const clearDateSearch = () => {
		setSelectedDayOption(null);
		setMinimumDonationDate('');
		setIsOpen(false);
	};

	const selectDonationType = (option: DonationTypeOption) => {
		setSelectedDonationType(option);
		setMinimumDonationDate('');
		setSelectedDayOption(null);
	};

	const isCustomSelected = selectedDayOption?.value === DateRangeOption.Custom;

	const filteredDayOptions = dayOptions.filter(option => {
		if (option.value === DateRangeOption.Custom && !showCustomRange) {
			return false;
		}
		return true;
	});

	return (
		<Dropdown
			anchor={
				<div className={css(dropdownStyles.dropdownAnchor)}>
					<span className={css(baseStyleSheet.truncateText)}>{dateRangeMessage}</span>
					<DisclosureIcon className={css(dropdownStyles.dropdownIcon)} />
				</div>
			}
			disabled={false}
			className={css(dropdownStyles.dropdownInline, isOpen ? dropdownStyles.dropdownInlineOpen : null)}
			contentClassName={css(dropdownStyles.dropdownMenu)}
			contentPositionY='bottom'
			contentInnerClassName={css(styleSheet.dropdownContentInnerOverrides)}
			isOpen={isOpen}
			onOpenChanged={() => setIsOpen(!isOpen)}
			openOnClick
		>
			<div className={css(dropdownStyles.dropdown)}>
				<div className={css(styleSheet.donationTypeOptions)}>
					<RadioButton
						checked={selectedDonationType === DonationTypeOption.HasDonated}
						id={DonationTypeOption.HasDonated}
						onChange={() => selectDonationType(DonationTypeOption.HasDonated)}
					>
						<span>{DonationTypeOption.HasDonated}</span>
					</RadioButton>

					<RadioButton
						checked={selectedDonationType === DonationTypeOption.NotDonated}
						id={DonationTypeOption.NotDonated}
						onChange={() => selectDonationType(DonationTypeOption.NotDonated)}
					>
						<span>{DonationTypeOption.NotDonated}</span>
					</RadioButton>
				</div>

				<div className={css(dropdownStyles.dropdownSeparator)} />

				<div>
					{isCustomSelected ? (
						<CustomDateRange
							endDateRequired
							from={minimumDonationDate ? moment(minimumDonationDate).toDate() : null}
							to={maximumDonationDate ? moment(maximumDonationDate).toDate() : null}
							onChange={(from, to) => {
								if (to === from) {
									return;
								}
								setMinimumDonationDate(moment(from).format('YYYY-MM-DD'));
								setMaximumDonationDate(moment(to).format('YYYY-MM-DD'));
							}}
							showTextInput
							disableScrollTo
						/>
					) : null}

					{!isCustomSelected &&
						filteredDayOptions.map(dayOption => {
							return (
								<div
									key={`donation-date-range-option-${dayOption.key}`}
									onClick={(e: React.MouseEvent<HTMLDivElement>) => selectDateRangeOption(dayOption, e)}
									className={css(
										dropdownStyles.option,
										selectedDayOption?.key === dayOption.key ? dropdownStyles.selected : null
									)}
								>
									{dayOption.label}
								</div>
							);
						})}
				</div>

				<div className={css(dropdownStyles.dropdownSeparator)} />

				{minimumDonationDate || isCustomSelected ? (
					<button className={css(baseStyleSheet.ctaButton)} onClick={clearDateSearch}>
						Reset
					</button>
				) : null}
			</div>
		</Dropdown>
	);
};
