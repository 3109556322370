import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import equal from 'fast-deep-equal';
import { inject, observer } from 'mobx-react';
import { parse } from 'query-string';
import * as React from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { ILocationState } from '../../../../models';
import {
	FullScreenModalViewModelKey,
	IFullscreenModalComponentProps,
	IQuickAddEntityComponentProps,
	IUserSessionComponentProps,
	QuickAddEntityViewModelKey,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import { useEventLogging } from '../../../../models/Logging';
import { queryStringParamsToSearchRequest, searchRequestToQueryStringParams } from '../../../../models/UiUtils';
import { useErrorMessages } from '../../../../models/hooks/appStateHooks';
import { useInput } from '../../../../models/hooks/inputHooks';
import { Button } from '../../../components/Button';
import { bs } from '../../../styles/styles';
import { ContactsMain } from '../shared/ContactsMain';
import { ContactsTableLoader } from '../shared/ContactsTableLoader';
import { CompaniesActionsBar } from './CompaniesActionsBar';
import {
	CompaniesSearchInputContext,
	CompaniesTab,
	CompaniesTabContext,
	CompanySearchContext,
	DefaultCompaniesFilter,
	DefaultCompaniesSort,
	getCompanySearchFromCriteria,
	getCompanyTabFromCriteria,
} from './CompaniesContext';
import { CompaniesSearchBarContainer } from './CompaniesSearchBarContainer';
import { CompaniesSearchDescription } from './CompaniesSearchDescription';
import { CompaniesTable } from './CompaniesTable';
import { styleSheet } from './styles';

interface IProps extends IQuickAddEntityComponentProps, IFullscreenModalComponentProps, IUserSessionComponentProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

interface IRedirect {
	pathname: string;
	state: ILocationState<Api.CompanyViewModel, Api.ICompany>;
}

export const CompaniesContainer: React.FC<IProps> = inject(
	QuickAddEntityViewModelKey,
	FullScreenModalViewModelKey,
	UserSessionViewModelKey
)(
	observer(props => {
		const { className, styles, quickAddEntity, userSession, fullscreenModal } = props;
		const history = useHistory();
		const location = useLocation();
		const errorMessages = useErrorMessages();
		const { logApiError } = useEventLogging();
		// @ts-ignore
		const [redirect, setRedirect] = React.useState<IRedirect>(null);
		// @ts-ignore
		const companies = React.useRef(new Api.CompaniesViewModel(userSession)).current;

		// Load search from query
		const params = parse(location?.search);
		const companiesQuery = queryStringParamsToSearchRequest<Api.ICompaniesSearchFilter>(params);
		const queryFilter: Api.ICompaniesSearchFilter = companiesQuery?.searchRequest || DefaultCompaniesFilter;
		const querySort = companiesQuery?.sortDescriptor || DefaultCompaniesSort;

		// Filter Context
		const [filter, setFilter] = React.useState<Api.ICompaniesSearchFilter>(queryFilter);
		const [sort, setSort] = React.useState<Api.ISortDescriptor>(querySort);
		const load = React.useCallback(
			(newFilter: Api.ICompaniesSearchFilter, newSort: Api.ISortDescriptor) => {
				const query = searchRequestToQueryStringParams<Api.ICompaniesSearchFilter>(newFilter, newSort);
				if (
					(!equal(query?.search, params?.search) || !equal(query?.sort, params?.sort)) &&
					query &&
					query.search &&
					query.sort
				) {
					history?.push(`/companies/?search=${query.search}&sort=${query.sort}`);
				}
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[filter, sort]
		);
		const filterValue = React.useMemo(() => ({ companies, filter, load, sort }), [filter, sort, load, companies]);

		// Search Input Context
		// @ts-ignore
		const [search, setSearch, onSearchChange] = useInput(getCompanySearchFromCriteria(queryFilter.criteria));
		// eslint-disable-next-line react-hooks/exhaustive-deps
		const searchValue = React.useMemo(() => ({ onSearchChange, search, setSearch }), [search]);

		// Tab Context
		// @ts-ignore
		const [tab, setTab] = React.useState<CompaniesTab>(getCompanyTabFromCriteria(queryFilter.criteria));
		const tabValue = React.useMemo(() => ({ setTab, tab }), [tab]);

		// Push first filter into query on mount
		React.useEffect(() => {
			load(filter, sort);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		// Update states when query changes
		React.useEffect(() => {
			setFilter(queryFilter);
			setSort(querySort);
			// @ts-ignore
			setSearch(getCompanySearchFromCriteria(queryFilter.criteria));
			// @ts-ignore
			setTab(getCompanyTabFromCriteria(queryFilter.criteria));
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [location]);

		// Fetch new results when the query changes
		React.useEffect(() => {
			// @ts-ignore
			companies.getCompanies({ searches: [...filter.criteria] }, sort)?.catch((err: Api.IOperationResultNoValue) => {
				// @ts-ignore
				errorMessages.pushApiError(err);
				logApiError('LoadCompanies-Error', err);
			});
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [filter, sort]);

		// "New Company" modal
		const onShowQuickAddContactModal = React.useCallback(() => {
			// @ts-ignore
			quickAddEntity.show({
				entity: {},
				onComplete: (error: Api.IOperationResultNoValue, companyModel?: Api.ICompany) => {
					if (!error && !!companyModel) {
						// @ts-ignore
						quickAddEntity.reset();
						const locationState: ILocationState<Api.CompanyViewModel, Api.ICompany> = {
							// @ts-ignore
							viewModel: new Api.CompanyViewModel(userSession, companyModel),
						};
						const companyLink = {
							pathname: `/companies/${companyModel.id}`,
							state: locationState,
						};
						if (fullscreenModal) {
							fullscreenModal.history.push(companyLink);
						} else {
							setRedirect(companyLink);
						}
					}
				},
				type: 'company',
			});
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		if (redirect) {
			return <Redirect push={true} to={redirect} />;
		}

		return (
			<CompaniesSearchInputContext.Provider value={searchValue}>
				<CompaniesTabContext.Provider value={tabValue}>
					<CompanySearchContext.Provider value={filterValue}>
						<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
							<CompaniesSearchBarContainer>
								<Button
									label='New Company'
									className={css(styleSheet.newCompany)}
									onClick={onShowQuickAddContactModal}
								/>
							</CompaniesSearchBarContainer>
							<ContactsMain>
								<div className={css(styleSheet.right)}>
									<CompaniesActionsBar />
									<div className={css(styleSheet.rightCard)}>
										<div
											className={css(bs.flex, bs.itemsCenter, bs.justifyBetween, bs.py0, bs.px7)}
											style={{ minHeight: 52 }}
										>
											<CompaniesSearchDescription />
										</div>
										{companies.isFetchingResults && !companies.companies.length ? (
											<ContactsTableLoader />
										) : (
											<CompaniesTable />
										)}
									</div>
								</div>
							</ContactsMain>
						</div>
					</CompanySearchContext.Provider>
				</CompaniesTabContext.Provider>
			</CompaniesSearchInputContext.Provider>
		);
	})
);
