import { ActionItemsViewModel, IRange, IUser } from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { NotesDateFilterOptionValue, NotesSortOptionValue } from '../../../../models';
import { formatDateRangeString } from '../../../../models/UiUtils';
import { DateRangePickerSideModal } from '../../DateRangePickerSide';
import { Portal } from '../../Portal';
import { ISelectOption, Select } from '../../Select';
import { TextInput } from '../../TextInput';
import { SearchIcon } from '../../svgs/icons/SearchIcon';
import { ActionItemsFilter, ActionItemsFilterValue } from '../ActionItemsFilter';
import { styleSheet } from './styles';

export const NoteDateOptions: ISelectOption<NotesDateFilterOptionValue>[] = [
	{
		dataContext: 'date',
		id: 'note-option-mine-asc',
		text: 'Filter by Date',
	},
];
export const NoteSortOptions: ISelectOption<NotesSortOptionValue>[] = [
	{
		dataContext: 'DueDate-asc',
		id: 'action-sort-option-due-date-asc',
		text: 'Due date (oldest first)',
	},
	{
		dataContext: 'DueDate-dsc',
		id: 'action-sort-option-due-date-dsc',
		text: 'Due date (newest first)',
	},
	{
		dataContext: 'LastModifiedDate-asc',
		id: 'action-sort-option-last-modified-date-asc',
		text: 'Last Modified date (oldest first)',
	},
	{
		dataContext: 'LastModifiedDate-dsc',
		id: 'action-sort-option-last-modified-date-dsc',
		text: 'Last Modified date (newest first)',
	},
	{
		dataContext: 'CreationDate-asc',
		id: 'action-sort-option-creation-date-asc',
		text: 'Creation date (oldest first)',
	},
	{
		dataContext: 'CreationDate-dsc',
		id: 'action-sort-option-creation-date-dsc',
		text: 'Creation date (newest first)',
	},
];

interface IProps {
	actionItemsVm?: ActionItemsViewModel;
	className?: string;
	onClearInput?(): void;
	onFilterSelectedOptionChanged?(selectedFilterValue: ActionItemsFilterValue, user?: IUser): void;
	onSearchInputBlur?(e: React.FocusEvent<HTMLInputElement>): void;
	onSearchInputChanged?(e: React.ChangeEvent<HTMLInputElement>): void;
	onSearchInputKeyDown?(e: React.KeyboardEvent<HTMLInputElement>): void;
	onRangeChanged?(range: IRange<Date | null>): void;
	onSortSelectedOptionChanged?(selectedSortOption: NotesSortOptionValue): void;
	portalDestination?: HTMLElement | string;
	selectedFilterValue?: ActionItemsFilterValue;
}

interface IState {
	selectedFilterValue?: ActionItemsFilterValue;
	dateRange: IRange<Date>;
	showDateSelector: boolean;
	selectedDateFilterOption: ISelectOption<NotesDateFilterOptionValue>;
	selectedDateRangeText: string;
	selectedSortOption: ISelectOption<NotesSortOptionValue>;
	selectedSortText: string;
}

const DefaultDateRangeText = 'Filter by Date';

class _ActionItemsListHeader extends React.Component<IProps, IState> {
	public readonly state: IState = {
		selectedFilterValue: this.props.selectedFilterValue,
		dateRange: { start: new Date(), end: new Date() },
		showDateSelector: false,
		selectedDateFilterOption: NoteDateOptions[0],
		selectedDateRangeText: DefaultDateRangeText,
		selectedSortOption: NoteSortOptions[0],
		// @ts-ignore
		selectedSortText: NoteSortOptions[0].text,
	};
	public componentDidMount() {
		this.props?.onSortSelectedOptionChanged?.(this.state.selectedSortOption.dataContext);
	}
	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		this.setState({ selectedFilterValue: nextProps.selectedFilterValue });
	}

	public render() {
		const {
			actionItemsVm,
			className,
			onClearInput,
			onSearchInputBlur,
			onSearchInputChanged,
			onSearchInputKeyDown,
			portalDestination,
			onRangeChanged,
		} = this.props;
		const {
			selectedFilterValue,
			dateRange,
			showDateSelector,
			selectedDateFilterOption,
			selectedDateRangeText,
			selectedSortOption,
			selectedSortText,
		} = this.state;
		const showSearch =
			selectedFilterValue === ActionItemsFilterValue.CurrentUser || selectedFilterValue === ActionItemsFilterValue.All;
		if (!actionItemsVm) {
			return null;
		}

		const onRangeChange = (range: IRange<Date>) => {
			const nextDateRange = {
				start: range.start,
				end: range.end,
			};
			this.setState({ dateRange: nextDateRange });
			onRangeChanged?.(nextDateRange);
		};

		const setSelectedDateFilterOption = (option: ISelectOption<NotesDateFilterOptionValue>) => {
			this.setState({ selectedDateFilterOption: option });
		};

		const setShowDateSelector = (show: boolean) => {
			this.setState({ showDateSelector: show });
		};

		const setSelectedDateRangeText = (text: string) => {
			this.setState({ selectedDateRangeText: text });
		};

		const setSelectedSortText = (text: string) => {
			this.setState({ selectedSortText: text });
		};

		const setSelectedSortOption = (option: ISelectOption<NotesSortOptionValue>) => {
			this.setState({ selectedSortOption: option });
			this.props?.onSortSelectedOptionChanged?.(option.dataContext);
		};

		const header = (
			<div className={`${css(styleSheet.header)} action-items-list-header ${className || ''}`}>
				<div>
					{showSearch ? (
						<div className={css(styleSheet.headerSearchWrap)}>
							<TextInput
								inputId='action-items-search-field'
								className={`${css(styleSheet.searchField)} action-items-search-field`}
								placeholder='Search action items'
								type='text'
								onBlur={onSearchInputBlur}
								onChange={onSearchInputChanged}
								value={actionItemsVm.searchQuery || ''}
								onKeyDown={onSearchInputKeyDown}
								leftAccessory={<SearchIcon className={css(styleSheet.icon)} />}
							/>
							{actionItemsVm?.searchQuery?.length > 0 && (
								<button onClick={onClearInput} className={css(styleSheet.clear)}>
									Clear
								</button>
							)}
						</div>
					) : null}
				</div>
				<div className={css(styleSheet.searchContainer)}>
					<Select
						options={NoteSortOptions}
						onOptionClick={option => {
							setSelectedSortOption(option as ISelectOption<NotesSortOptionValue>);
							// @ts-ignore
							setSelectedSortText(option.text);
						}}
						selectedOption={{ ...selectedSortOption, text: selectedSortText }}
						styles={[styleSheet.sortSelect]}
					/>
					<Select
						options={NoteDateOptions}
						onOptionClick={option => {
							setSelectedDateFilterOption(option);
							setShowDateSelector(true);
						}}
						selectedOption={{ ...selectedDateFilterOption, text: selectedDateRangeText }}
						styles={[styleSheet.dateSelect]}
					/>
					<DateRangePickerSideModal
						isOpen={showDateSelector}
						onRequestClose={(clear = false) => {
							setShowDateSelector(false);
							if (clear) {
								setSelectedDateRangeText(DefaultDateRangeText);
								// @ts-ignore
								onRangeChange({ start: null, end: null });
							} else {
								setSelectedDateRangeText(formatDateRangeString(dateRange));
							}
						}}
						onRangeChange={onRangeChange}
						range={dateRange}
					/>
					<ActionItemsFilter
						onFilterValueChanged={this.onFilterSelectedOptionChanged}
						selectedFilterValue={selectedFilterValue}
					/>
				</div>
			</div>
		);

		return portalDestination ? <Portal destination={portalDestination}>{header}</Portal> : header;
	}

	private onFilterSelectedOptionChanged = (selectedFilterValue: ActionItemsFilterValue, user?: IUser) => {
		const { onFilterSelectedOptionChanged } = this.props;
		this.setState({ selectedFilterValue });
		if (onFilterSelectedOptionChanged) {
			onFilterSelectedOptionChanged(selectedFilterValue, user);
		}
	};
}

export const ActionItemsListHeader = observer(_ActionItemsListHeader);
