import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { UserViewModel } from '../../../extViewmodels';
import { useEventLogging } from '../../../models/Logging';
import { useErrorMessages, useTextMessaging, useToaster, useUserSession } from '../../../models/hooks/appStateHooks';
import { useResolvePhoneNumber, useTelephonyTextSettingsQuery } from '../../../queries';
import { AppBarNavigationItemPortalDestinationId } from '../../components/AppBar';
import { Button } from '../../components/Button';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { Modal } from '../../components/Modal';
import { Portal } from '../../components/Portal';
import { TextArea } from '../../components/TextArea';
import { Toggle } from '../../components/Toggle';
import { DangerMessage } from '../../components/errorMessages/DangerMessage';
import { Conversations } from '../../components/texting/Conversations';
import { Setup } from '../../components/texting/Setup';
import { TextRegistrationInProgressModal } from '../../components/texting/TextRegistrationInProgressModal';
import { TextRegistrationRequiredModal } from '../../components/texting/TextRegistrationRequiredModal';
import { TextingProvidersMigration } from '../../components/texting/TextingProvidersMigration';
import { grayIconFill } from '../../styles/colors';
import { baseStyleSheet } from '../../styles/styles';
import { useTextingCampaignRegistrationContext } from './TextingCampaignRegistrationPrompt/context';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const TextingBase: React.FC<IProps> = ({ className, styles }) => {
	const errorMessages = useErrorMessages();
	const logger = useEventLogging();
	const textMessagingVM = useTextMessaging();
	const userSession = useUserSession();
	const toaster = useToaster();
	const history = useHistory();

	const userViewModel = useRef(new UserViewModel(userSession, userSession.user)).current;

	const [showManageTextAutoReply, setShowManageTextAutoReply] = React.useState(false);
	const [textAutoReplyEnabled, setTextAutoReplyEnabled] = React.useState(false);
	const [textAutoReplyMessage, setTextAutoReplyMessage] = React.useState('');
	const [saveButtonEnabled, setSaveButtonEnabled] = React.useState(true);
	const {
		showRegistrationRequiredModal,
		isCampaignRegistrationRequired,
		showRegistrationInProgressModal,
		isRegistrationInProgress,
		setShowRegistrationRequiredModal,
		setShowRegistrationInProgressModal,
		isLoading,
		textSettings,
		checkShowRegistration,
	} = useTextingCampaignRegistrationContext();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	React.useEffect(checkShowRegistration, []);

	const [toggleProvidersMigrationModal, setProvidersMigrationModal] = React.useState(false);

	const shouldRenderMigrationWarning = textMessagingVM?.phoneNumberOrder?.isUsingTemporarySendingNumber;
	const phoneNumberId = textMessagingVM?.phoneNumberOrder?.sendingPhoneNumberId;

	const resolvePhoneNumberQuery = useResolvePhoneNumber({
		enabled: Boolean(shouldRenderMigrationWarning && phoneNumberId),
		phoneNumberId,
	});
	// turn off caching as we don't want to read a stale value and block the user from texting after reg. is successful
	const telephonyTextSettingsQuery = useTelephonyTextSettingsQuery({ cacheTime: 0 });

	React.useEffect(() => {
		if (
			textMessagingVM.isLoaded &&
			textMessagingVM.number == null &&
			telephonyTextSettingsQuery.data &&
			!telephonyTextSettingsQuery.data.canRequestTextingPhoneNumber
		) {
			history.push('/texting/registration');
		}
	}, [telephonyTextSettingsQuery.data, history, textMessagingVM.isLoaded, textMessagingVM.number]);

	useEffect(() => {
		// @ts-ignore
		if (textMessagingVM.isLoaded === undefined) {
			// @ts-ignore
			textMessagingVM.getUserPhone();
		}
	}, [textMessagingVM]);

	useEffect(() => {
		setTextAutoReplyEnabled(userViewModel.toJs().textAutoReply?.enabled ?? false);
		setTextAutoReplyMessage(
			userViewModel.toJs().textAutoReply?.message ??
				'[Auto Reply] Thanks for your text. I am out of the office right now and will respond as soon as I can.'
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userViewModel.isLoaded]);

	useEffect(() => {
		setSaveButtonEnabled(!!textAutoReplyMessage && textAutoReplyMessage.length > 0);
	}, [textAutoReplyMessage]);

	const setupComplete = async () => {
		try {
			// @ts-ignore
			return await textMessagingVM.getUserPhone();
		} catch (err) {
			// @ts-ignore
			logger.logApiError('GetUserPhone-Error', err);
			// @ts-ignore
			// @ts-ignore
			errorMessages.push(err);
		}
	};

	const onSaveClicked = async () => {
		try {
			await userViewModel.updateTextAutoReplySettings({
				enabled: true,
				message: textAutoReplyMessage,
			});

			// @ts-ignore
			toaster.push({
				message: 'Successfully updated text out of office response.',
				type: 'successMessage',
			});

			toggleShowManageTextAutoReplyModal();
		} catch (err) {
			// @ts-ignore
			// @ts-ignore
			errorMessages.push(err);
		}
	};

	const onCancelClicked = () => {
		toggleShowManageTextAutoReplyModal();
	};

	const toggleShowManageTextAutoReplyModal = () => {
		setShowManageTextAutoReply(!showManageTextAutoReply);
	};

	const onCloseRegistrationWarningClicked = () => {
		setShowRegistrationRequiredModal(false);
	};

	const onCloseRegistrationInProgressClicked = () => {
		setShowRegistrationInProgressModal(false);
	};

	const onTextAreaUpdated = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setTextAutoReplyMessage(e.target.value);
	};

	const onToggleCheckChanged = async (checked: boolean) => {
		if (checked) {
			setShowManageTextAutoReply(true);
		}

		if (!checked) {
			try {
				await userViewModel.updateTextAutoReplySettings({
					enabled: false,
					message: textAutoReplyMessage,
				});
			} catch (err) {
				// @ts-ignore
				// @ts-ignore
				errorMessages.push(err);
			}
		}

		setTextAutoReplyEnabled(checked);
	};

	const renderTextingViews = textMessagingVM.id ? (
		<Conversations
			requiresCampaignRegistration={isCampaignRegistrationRequired}
			isRegistrationInProgress={isRegistrationInProgress}
		/>
	) : (
		<Setup onSetupComplete={setupComplete} />
	);

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
			<Portal destination={AppBarNavigationItemPortalDestinationId}>
				<h1 className={css(baseStyleSheet.breadcrumbTitle)}>Texting</h1>
			</Portal>
			<div className={css(styleSheet.autoReply)}>
				{shouldRenderMigrationWarning ? (
					<DangerMessage type='warning'>
						Your texts are being sent with a temporary number while we are migrating providers.{' '}
						<button className={css(baseStyleSheet.brandLink)} onClick={() => setProvidersMigrationModal(true)}>
							<strong className={css(baseStyleSheet.fontBold)}>Read More</strong>
						</button>
					</DangerMessage>
				) : (
					<div />
				)}
				<div>
					<Toggle
						id='outOfOfficeToggle'
						text='Automatic "Out of Office" response'
						uncheckedColor={grayIconFill}
						isOn={textAutoReplyEnabled}
						onToggleCheckChanged={onToggleCheckChanged}
					/>
					{textAutoReplyEnabled && (
						<button
							className={css(baseStyleSheet.link, baseStyleSheet.brandLink, styleSheet.manageButton)}
							onClick={toggleShowManageTextAutoReplyModal}
						>
							<span>Manage</span>
						</button>
					)}
				</div>
			</div>
			<div className={css(styleSheet.wrapper)}>
				{textMessagingVM.isLoading || !textMessagingVM.isLoaded || telephonyTextSettingsQuery.isLoading || isLoading ? (
					<LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} type='large' />
				) : (
					renderTextingViews
				)}
			</div>
			<Modal
				isOpen={toggleProvidersMigrationModal}
				onRequestClose={() => setProvidersMigrationModal(false)}
				useDefaultHeader={true}
			>
				<TextingProvidersMigration temporaryNumber={resolvePhoneNumberQuery?.data?.number} />
			</Modal>
			<Modal isOpen={showManageTextAutoReply} useDefaultHeader={true} onRequestClose={onCancelClicked}>
				<div className={css(styleSheet.modal)}>
					<div className={css(styleSheet.modalTitle)}>Automatic &quot;Out of Office&quot; Response</div>
					<div>
						<TextArea
							autoComplete='off'
							className={css(styleSheet.autoReplyMessageTextField)}
							inputId='create-email-templates-summary-input'
							onChange={onTextAreaUpdated}
							value={textAutoReplyMessage}
						/>
						<div className={css(styleSheet.footer)}>
							<Button label='Save' size='normal' disabled={!saveButtonEnabled} onClick={onSaveClicked} />
							<Button label='Cancel' size='normal' kind='reverse' onClick={onCancelClicked} />
						</div>
					</div>
				</div>
			</Modal>
			<TextRegistrationRequiredModal
				showRegistrationRequiredModal={showRegistrationRequiredModal}
				telephonyTextSettings={textSettings}
				onRequestClose={onCloseRegistrationWarningClicked}
			/>
			<TextRegistrationInProgressModal
				showRegistrationRequiredModal={showRegistrationInProgressModal}
				onRequestClose={onCloseRegistrationInProgressClicked}
			/>
		</div>
	);
};

export const Texting = observer(TextingBase);
